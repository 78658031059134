import { useState, useEffect, useContext } from "react";
import GoogleMap from "./GoogleMapAPI/GoogleMap";
import GridLayoutDisplay from "./GridLayoutDisplay/GridLayoutDisplay";
import axios from "axios";
import Cookies from "js-cookie";
import { MyContextMapAndGrid } from "../../../App";
import Swal from "sweetalert2";
import useEffectOnce from "../../hook/useEffectOnce";
// import Snowfall from "react-snowfall";

const Home = () => {
  // this is googel map modal detail & grid modal detail
  const [isExpanded, setIsExpanded] = useState(false);
  const [heightStep, setHeightStep] = useState('half');
  const [mainDataModal, setMainDataModal] = useState(null);

  // this is googel map modal detail & grid modal detail
  

  useEffectOnce(()=>{
    axios.post(`${process.env.REACT_APP_ADMIN_API}/event/count-viewer`,{

    }).then((res)=>{
      console.log(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  })
  const statusFirstComeReqLocation = localStorage.getItem(
    "notificationReqLocationFirstCome"
  );  

  // If the key doesn't exist in localStorage, it's the user's first visit
  const isFirstVisit = statusFirstComeReqLocation === null;

  // Set the modal's initial status based on whether it's the user's first visit
  const [isModalReqLocationFirstCome, setIsModalReqLocationFirstCome] =
    useState(isFirstVisit);

  const statusInSessionCloseMap = sessionStorage.getItem("statusCloseMap");

  const tempURLProductForBackwardAfterLoginOrRegister = sessionStorage.getItem(
    "tempURLProductForBackwardAfterLoginOrRegister"
  );
  useEffect(() => {
    if (tempURLProductForBackwardAfterLoginOrRegister !== null) {
      window.location.replace(tempURLProductForBackwardAfterLoginOrRegister);
      sessionStorage.removeItem(
        "tempURLProductForBackwardAfterLoginOrRegister"
      );
    }
  }, []);

  const {
    statusCloseMap,
    SetStatusCloseMap,
    statusCloseGrid,
    setStatusCloseGrid,
    statusHalfGridMap,
    setStatusHalfGridMap,
    isFullMapButtonOn,
    setIsFullMapButtonOn,
    isFullGridButtonOn,
    setIsFullGridButtonOn,
    searchValue,
    setSearchValue,
    dataSearchValue,
    setDataSearchValue,
    dataDisplay,
    setDataDisplay,

  } = useContext(MyContextMapAndGrid);

  const [hideScreenMobile, setHideScreenMobile] = useState(false);

  const changeStatusMap = () => {
    setStatusHalfGridMap(!statusHalfGridMap);
    setStatusCloseGrid(!statusCloseGrid);
    setIsFullMapButtonOn(!isFullMapButtonOn);
  };

  const changeStatusGrid = () => {
    setStatusHalfGridMap(!statusHalfGridMap);
    SetStatusCloseMap(!statusCloseMap);
    setIsFullGridButtonOn(!isFullGridButtonOn);
  };

  const getCookie = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    const checkSizeScreen = () => {
      setHideScreenMobile(window.innerWidth <= 820);
    };

    // Call the function immediately on component mount
    checkSizeScreen();

    // Setup the event listener
    window.addEventListener("resize", checkSizeScreen);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", checkSizeScreen);
  }, []);

  
  useEffect(() => {
    if (searchValue !== "") {
      axios
        .get(`${process.env.REACT_APP_API}/event/search/${searchValue}`)
        .then((res) => {
          setDataSearchValue(res.data.assets);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      // reset search value to empty string for displayData is can map data
      setDataSearchValue("");
    }
  }, [searchValue]);

  const panToUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // console.log(pos)
          localStorage.setItem("LatForRecommendedAssets", pos.lat);
          localStorage.setItem("LngForRecommendedAssets", pos.lng);
          // setCurrentLocation(pos);
          Swal.fire("ขอตำแหน่งสำเร็จ", "", "success");
        },
        (positionError) => {},
        { enableHighAccuracy: true }
      );
    }
  };

  function handleNotificationReqLocation(status) {
    if (status === "approved") {
      panToUserLocation();
      localStorage.setItem("notificationReqLocationFirstCome", "false");
    }
    setIsModalReqLocationFirstCome(false);
  }

  const mapClassNames = `${
    statusHalfGridMap
      ? "col-span-12 lg:col-span-6"
      : statusCloseGrid
      ? "col-span-12"
      : statusCloseMap
      ? "hidden"
      : ""
  }`;

  const gridLayoutClassNames = `${
    statusHalfGridMap
      ? "max-sm:hidden hidden lg:block lg:col-span-6"
      : statusCloseGrid
      ? "hidden"
      : statusCloseMap
      ? "col-span-12"
      : ""
  }`;

  return (
    <div className="w-full relative">
      {/* <div className="max-w-4xl w-full h-auto mx-auto flex items-center hidden sm:flex">


      </div> */}
      {/* <Snowfall snowflakeCount={150} /> */}
{/* 
      <div
        className={
          statusCloseGrid === true
            ? "hidden"
            : isFullGridButtonOn
            ? "cursor-pointer absolute bottom-2/3 z-50 bg-white rounded-full left-10 transform -translate-x-1/2 -translate-y-1/2"
            : "cursor-pointer absolute bottom-2/3 z-50 bg-white rounded-full left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        }
        onClick={changeStatusGrid}
      >
        <img
          src={
            isFullGridButtonOn
              ? "https://cdn-icons-png.flaticon.com/512/10692/10692075.png"
              : "https://cdn-icons-png.flaticon.com/512/747/747327.png"
          }
          className={hideScreenMobile ? "hidden" : "w-10 h-10  p-2"}
        />
      </div> */}

      {/* <div
        className={
          statusCloseMap === true
            ? "hidden"
            : isFullMapButtonOn
            ? "cursor-pointer absolute top-1/2 right-10 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white rounded-full"
            : "cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white rounded-full"
        }
        onClick={changeStatusMap}
      >
        <img
          src={
            isFullMapButtonOn
              ? "https://cdn-icons-png.flaticon.com/512/271/271220.png "
              : "https://cdn-icons-png.flaticon.com/512/2335/2335353.png"
          }
          className={hideScreenMobile ? "hidden" : "w-10 h-10  p-2"}
        />
      </div> */}

      <div className="md:grid md:grid-cols-12 h-auto ">
        <div
          className={
            statusHalfGridMap === true
              ? "col-span-12 lg:col-span-7"
              : statusHalfGridMap === false
              ? statusCloseGrid === true
                ? "col-span-12"
                : statusCloseGrid === false
                ? statusCloseMap === true
                  ? "hidden"
                  : ""
                : ""
              : ""
          }
        >
          <GoogleMap
            statusInSessionCloseMap={statusInSessionCloseMap}
            setDataSearchValue={setDataSearchValue}
            setSearchValue={setSearchValue}
            statusHalfGridMap={statusHalfGridMap}
            setStatusHalfGridMap={setStatusHalfGridMap}
            statusCloseMap={statusCloseMap}
            SetStatusCloseMap={SetStatusCloseMap}
            isFullGridButtonOn={isFullGridButtonOn}
            setIsFullGridButtonOn={setIsFullGridButtonOn}
            setDataDisplay={setDataDisplay}
            setIsExpanded={setIsExpanded}
            isExpanded={isExpanded}
            setHeightStep={setHeightStep}
            heightStep={heightStep}
            mainDataModal={mainDataModal}
            setMainDataModal={setMainDataModal}
          />
        </div>

        <div
          className={
            statusHalfGridMap === true
              ? "max-sm:hidden hidden lg:block  lg:col-span-5"
              : statusHalfGridMap === false
              ? statusCloseGrid === true
                ? "hidden"
                : statusCloseGrid === false
                ? statusCloseMap === true
                  ? "col-span-12"
                  : ""
                : ""
              : ""
          }
        >
          <GridLayoutDisplay
            statusCloseMap={statusCloseMap}
            statusHalfGridMap={statusHalfGridMap}
            setStatusHalfGridMap={setStatusHalfGridMap}
            SetStatusCloseMap={SetStatusCloseMap}
            dataSearchValue={dataSearchValue}
            setSearchValue={setSearchValue}
            dataDisplay={dataDisplay}
            setDataDisplay={setDataDisplay}
            setHeightStep={setHeightStep}
            heightStep={heightStep}
            mainDataModal={mainDataModal}
            setMainDataModal={setMainDataModal}
          />
        </div>
        {/* 
        <div className={mapClassNames}>
          <GoogleMap statusInSessionCloseMap={statusInSessionCloseMap} setDataSearchValue={setDataSearchValue} setSearchValue={setSearchValue} statusHalfGridMap={statusHalfGridMap} setStatusHalfGridMap={setStatusHalfGridMap} statusCloseMap={statusCloseMap} SetStatusCloseMap={SetStatusCloseMap} isFullGridButtonOn={isFullGridButtonOn} setIsFullGridButtonOn={setIsFullGridButtonOn} />
        </div>

        <div className={gridLayoutClassNames} >
          <GridLayoutDisplay statusCloseMap={statusCloseMap} statusHalfGridMap={statusHalfGridMap} setStatusHalfGridMap={setStatusHalfGridMap} SetStatusCloseMap={SetStatusCloseMap} dataSearchValue={dataSearchValue} setSearchValue={setSearchValue} />
        </div> */}
      </div>

      <div
        className={`${
          isModalReqLocationFirstCome
            ? "absolute top-1/2 left-1/2 tranform  -translate-x-1/2 -translate-y-1/2 h-[300px] w-11/12 rounded-xl bg-white md:w-[500px]  z-50"
            : "hidden"
        }`}
      >
        <h1 className="font-bold w-full text-center text-2xl mt-10">
          แจ้งเตือน
        </h1>
        <p className="w-10/12 mx-auto mt-4 text-center">
          "เพื่อประสบการณ์ใช้งานที่ดียิ่งขึ้นทางทีมpostpertyจะขอใช้ตำแหน่งของคุณเพื่อแนะนำทรัพย์บริเวณใกล้เคียงให้กับคุณ"
        </p>
        <div className="w-10/12 mt-6 h-[100px]  mx-auto flex justify-center items-center">
          <button
            className="h-12 border   w-[200px] rounded-full   bg-white"
            onClick={() => handleNotificationReqLocation("failed")}
          >
            ภายหลัง
          </button>
          <button
            className="h-12 border bg-postperty-linear-light  text-white  w-[200px] rounded-full  bg-white ml-2.5"
            onClick={() => handleNotificationReqLocation("approved")}
          >
            อนุญาต
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;

// const Home = ()=>{
//   return(
//     <div>Home TEST SSL</div>
//   )
// }

// export default Home
