import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight, Users2 } from "lucide-react";

export default function MembersLayerData() {
  const session_id = Cookies.get("jwt");
  const [dataDetailLayer, setDataDetailLayer] = useState([]);
  const [dataDetailPage, setDataDetailPage] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const dataValue = url.searchParams.get("data");
    setDataDetailPage(dataValue ? parseInt(dataValue) : 1);
  }, []);

  useEffect(() => {
    if (dataDetailPage !== null) {
      axios
        .get(
          `${process.env.REACT_APP_API}/mlm/invited-detail?layer=${dataDetailPage}`,
          {
            headers: {
              Authorization: `Bearer ${session_id}`,
            },
          }
        )
        .then((res) => {
          setDataDetailLayer(res.data.invitees || []);
        })
        .catch((error) => {
          console.error(error);
          setDataDetailLayer([]);
        });
    }
  }, [dataDetailPage, session_id]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
        ease: "easeOut"
      }
    })
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="min-h-screen w-full bg-cover bg-center bg-fixed relative"
      style={{
        backgroundImage: `url('https://images.unsplash.com/photo-1554050857-c84a8abdb5e2?q=80&w=2127&auto=format&fit=crop')`
      }}
    >
      {/* Navigation Header */}
      <div className="sticky top-0 z-10 w-full bg-white/80 backdrop-blur-md shadow-lg">
        <div className="max-w-4xl mx-auto px-4 py-4 flex items-center justify-between">
          <Link
            to={dataDetailPage === 1 ? "/members/layer" : `/members/layer/desc?data=${dataDetailPage - 1}`}
            onClick={() => setDataDetailPage((prev) => Math.max(prev - 1, 1))}
            className="p-2 bg-white rounded-xl shadow-md hover:bg-blue-50 transition-colors duration-200"
          >
            <ChevronLeft className="w-6 h-6 text-blue-600" />
          </Link>
          
          <div className="flex items-center gap-2">
            <div className="bg-gradient-to-r from-blue-600 to-blue-400 text-white px-4 py-2 rounded-xl shadow-md">
              <span className="font-medium">ชั้นที่ {dataDetailPage}</span>
            </div>
            <div className="bg-white px-4 py-2 rounded-xl shadow-md">
              <span className="text-blue-600 font-medium">{dataDetailLayer.length} คน</span>
            </div>
          </div>

          {dataDetailPage !== 10 && (
            <Link
              to={`/members/layer/desc?data=${dataDetailPage + 1}`}
              onClick={() => setDataDetailPage((prev) => Math.min(prev + 1, 10))}
              className="p-2 bg-white rounded-xl shadow-md hover:bg-blue-50 transition-colors duration-200"
            >
              <ChevronRight className="w-6 h-6 text-blue-600" />
            </Link>
          )}
          {dataDetailPage === 10 && <div className="w-10" />}
        </div>
      </div>

      {/* Content */}
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="space-y-4">
          {dataDetailLayer.length > 0 ? (
            dataDetailLayer.map((data, index) => (
              <motion.div
                key={index}
                custom={index}
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                className="bg-white/90 backdrop-blur-md rounded-2xl shadow-lg p-4 transform transition-all duration-300 hover:scale-102 hover:bg-white/95"
              >
                <div className="flex items-center gap-6">
                  <div className="relative">
                    <div className="w-16 h-16 rounded-full overflow-hidden ring-2 ring-blue-400 ring-offset-2">
                      <img
                        src={data.profile_image}
                        className="w-full h-full object-cover"
                        alt={`${data.uname}'s profile`}
                      />
                    </div>
                    <div className="absolute -top-2 -right-2 bg-gradient-to-r from-blue-500 to-blue-400 text-white text-xs px-2 py-1 rounded-full shadow-md">
                      {index + 1}
                    </div>
                  </div>
                  
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold text-blue-900">{data.uname}</h3>
                    <p className="text-sm text-blue-600">สมาชิก</p>
                  </div>
                </div>
              </motion.div>
            ))
          ) : (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center justify-center py-20"
            >
              <div className="bg-white/90 backdrop-blur-md rounded-2xl p-8 shadow-lg text-center">
                <Users2 className="w-16 h-16 text-blue-400 mx-auto mb-4" />
                <h3 className="text-xl font-semibold text-blue-900 mb-2">ไม่มีข้อมูลสมาชิก</h3>
                <p className="text-blue-600">ไม่พบข้อมูลสมาชิกในระดับนี้</p>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
}