import React, { useState } from "react";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';

const Settings = () => {
    const [locationAllowed, setLocationAllowed] = useState(false);
    const [isPDPAModalOpen, setIsPDPAModalOpen] = useState(false);

    const pdpaContent = `เว็บไซต์ **Postperty.com** โดยครอบคลุมประเด็นสำคัญต่าง ๆ เช่น นโยบายความเป็นส่วนตัว (Privacy Policy) การขอความยินยอม (Consent) และมาตรการรักษาความปลอดภัยของข้อมูล (Data Security)  

### 1. **นโยบายความเป็นส่วนตัว (Privacy Policy)**  
**Postperty.com** มุ่งมั่นในการคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้งานตามกฎหมาย PDPA (พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562) นโยบายนี้อธิบายถึงวิธีการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของคุณ  

#### ข้อมูลที่เรารวบรวม  
- ข้อมูลส่วนบุคคล เช่น ชื่อ นามสกุล ที่อยู่ เบอร์โทรศัพท์ อีเมล  
- ข้อมูลบัญชีผู้ใช้งาน เช่น ชื่อผู้ใช้งาน (Username) และรหัสผ่าน (Password)  
- ข้อมูลการใช้งานเว็บไซต์ เช่น ประวัติการเข้าชม (Log Data) และคุกกี้ (Cookies)  
- ข้อมูล ตำแหน่ง พิกัด GPS ที่ได้ จากอุปกรณ์ สื่อสาร ในขณะที่ลูกค้ากำลังใช้งาน เวปไซต์

#### วัตถุประสงค์ของการใช้ข้อมูล  
- เพื่อการลงทะเบียนและให้บริการบนเว็บไซต์  
- เพื่อการติดต่อสื่อสารและให้ข้อมูลเกี่ยวกับบริการ  
- เพื่อการปรับปรุงและพัฒนาบริการของเรา  
- เพื่อการตลาดและเสนอโปรโมชันต่าง ๆ (หากได้รับความยินยอมจากคุณ)  

#### การเปิดเผยข้อมูล  
เราจะไม่เปิดเผยข้อมูลส่วนบุคคลของคุณให้กับบุคคลที่สามโดยไม่ได้รับความยินยอมจากคุณ ยกเว้นในกรณีที่กฎหมายกำหนด  

#### สิทธิ์ของคุณ  
คุณมีสิทธิ์ดังต่อไปนี้:  
- ขอเข้าถึงข้อมูลส่วนบุคคลของคุณ  
- ขอแก้ไขข้อมูลส่วนบุคคลของคุณ  
- ขอลบข้อมูลส่วนบุคคลของคุณ  
- ถอนความยินยอมในการใช้ข้อมูลส่วนบุคคลของคุณ  

#### การติดต่อ  
หากมีคำถามหรือต้องการใช้สิทธิ์ตามกฎหมาย PDPA กรุณาติดต่อเราที่:  
- อีเมล: chanwit@postperty.com  
- เบอร์โทรศัพท์: 091-0920333

---

### 2. **การขอความยินยอม (Consent)**  
เมื่อผู้ใช้งานลงทะเบียนหรือใช้บริการบนเว็บไซต์ **Postperty.com** จะมีการแสดงข้อความขอความยินยอมดังนี้:  

> "เพื่อให้การให้บริการเป็นไปอย่างมีประสิทธิภาพ ทางเว็บไซต์ Postperty.com จำเป็นต้องเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของคุณตามนโยบายความเป็นส่วนตัว คุณสามารถอ่านรายละเอียดเพิ่มเติมได้ที่ [ลิงก์นโยบายความเป็นส่วนตัว] หากคุณยินยอม กรุณากดปุ่ม 'ยอมรับ' หรือดำเนินการต่อไปเพื่อใช้บริการ"  

**หมายเหตุ**:  
- ผู้ใช้งานสามารถถอนความยินยอมได้ตลอดเวลาโดยติดต่อเราผ่านช่องทางที่ระบุในนโยบายความเป็นส่วนตัว

### 3. **มาตรการรักษาความปลอดภัยของข้อมูล (Data Security)**  
**Postperty.com** มีมาตรการรักษาความปลอดภัยของข้อมูลส่วนบุคคลดังนี้:  
1. **การเข้ารหัสข้อมูล (Encryption)**: ข้อมูลส่วนบุคคลทั้งหมดจะถูกเข้ารหัสทั้งในระหว่างการส่งและจัดเก็บ  
2. **การควบคุมการเข้าถึง (Access Control)**: จำกัดการเข้าถึงข้อมูลส่วนบุคคลเฉพาะผู้ที่มีความจำเป็นต้องรู้เท่านั้น  
3. **การตรวจสอบระบบ (Audit)**: มีการตรวจสอบระบบอย่างสม่ำเสมอเพื่อป้องกันการเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต  
4. **การฝึกอบรมพนักงาน**: พนักงานทุกคนจะได้รับการฝึกอบรมเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลตามกฎหมาย PDPA  

---

### 4. **แผนรับมือกรณีข้อมูลรั่วไหล (Data Breach Response Plan)**  
หากเกิดกรณีข้อมูลรั่วไหล **Postperty.com** จะดำเนินการดังนี้:  
1. **ตรวจสอบและประเมินสถานการณ์**: ทีมงานจะตรวจสอบสาเหตุและขอบเขตของเหตุการณ์  
2. **แจ้งสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล (สคส.)**: จะแจ้งเหตุการณ์ภายใน 72 ชั่วโมงหลังจากทราบเหตุ  
3. **แจ้งผู้ใช้งานที่ได้รับผลกระทบ**: หากมีความเสี่ยงสูงต่อสิทธิเสรีภาพของผู้ใช้งาน จะแจ้งให้ผู้ใช้งานทราบพร้อมคำแนะนำในการป้องกันตนเอง  
4. **แก้ไขและป้องกันการเกิดซ้ำ**: จะดำเนินการแก้ไขจุดบกพร่องและปรับปรุงระบบเพื่อป้องกันการเกิดซ้ำ  

---

### 5. **การแต่งตั้ง DPO (เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล)**  
**Postperty.com** จะแต่งตั้ง DPO เพื่อดูแลการปฏิบัติตามกฎหมาย PDPA โดย DPO จะมีหน้าที่ดังนี้:  
- ให้คำแนะนำเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล  
- ตรวจสอบการปฏิบัติตามกฎหมาย PDPA  
- เป็นผู้ประสานงานกับสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล (สคส.)
`;

    const handleAllowLocationAccess = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocationAllowed(true);
                    Swal.fire(
                        "สำเร็จ!",
                        "การเข้าถึงตำแหน่งที่ตั้งได้รับอนุญาต",
                        "success"
                    );
                    console.log('Position:', position);
                },
                (error) => {
                    let errorMessage = '';
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = "การเข้าถึงตำแหน่งที่ตั้งถูกปฏิเสธโดยผู้ใช้ กรุณาดูยูทูปสอนเพื่อวิธีแก้ไขอนุญาตการเข้าถึงตำแหน่งของท่าน";
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = "ไม่สามารถระบุตำแหน่งได้";
                            break;
                        case error.TIMEOUT:
                            errorMessage = "การขอข้อมูลตำแหน่งเกินระยะเวลาที่กำหนด";
                            break;
                        default:
                            errorMessage = "เกิดข้อผิดพลาดในการขอตำแหน่งที่ตั้ง";
                            break;
                    }
                    Swal.fire("เกิดข้อผิดพลาด!", errorMessage, "error");
                }
            );
        } else {
            Swal.fire(
                "ไม่รองรับ",
                "เบราว์เซอร์ของคุณไม่รองรับการเข้าถึงตำแหน่งที่ตั้ง",
                "error"
            );
        }
    };

    const MenuButton = ({ label, onClick, iconSrc, extra }) => (
        <motion.div
            className="bg-white border border-blue-200 rounded-xl p-4 flex flex-col items-center justify-center cursor-pointer shadow-sm hover:shadow-md transition-shadow"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
        >
            <img src={iconSrc} alt={label} className="w-12 h-12 mb-2" />
            <h1 className="text-center text-base font-medium text-blue-600">
                {label}
            </h1>
            {extra && <div className="mt-2">{extra}</div>}
        </motion.div>
    );

    const PDPAModal = () => (
        <div 
            className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${isPDPAModalOpen ? 'block' : 'hidden'}`}
            onClick={() => setIsPDPAModalOpen(false)}
        >
            <div 
                className="bg-white w-11/12 md:w-3/4 max-h-[90vh] overflow-y-auto rounded-lg p-6 relative"
                onClick={(e) => e.stopPropagation()}
            >
                <button 
                    className="absolute top-4 right-4 text-2xl text-gray-600 hover:text-gray-900"
                    onClick={() => setIsPDPAModalOpen(false)}
                >
                    ×
                </button>
                <h2 className="text-2xl font-bold mb-4 text-blue-700">นโยบายความเป็นส่วนตัว (PDPA)</h2>
                <div className="prose max-w-full">
                    <ReactMarkdown >{pdpaContent}</ReactMarkdown>
                </div>
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-blue-50 p-4 flex flex-col items-center">
            {/* PDPA Modal */}
            <PDPAModal />

            <div className="w-full max-w-2xl h-screen overflow-y-auto ">
                <h1 className="text-2xl font-semibold text-center text-blue-700 mb-6">
                    การตั้งค่าบัญชี
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <MenuButton
                        label="ย้อนกลับ"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/2099/2099190.png"
                        onClick={() =>
                            window.location.href = "/profiles"
                        }
                    />
                    <MenuButton
                        label="อนุญาตการเข้าถึงตำแหน่งที่ตั้ง"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/17539/17539781.png"
                        onClick={handleAllowLocationAccess}
                        extra={
                            <Link
                                to="https://youtu.be/Yv21fdWMr-g"
                                target="_blank"
                                className="text-md text-red-500 underline"
                            >
                                วิธีการอนุญาต
                            </Link>
                        }
                    />
                    <MenuButton
                        label="PDPA"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/9195/9195918.png"
                        onClick={() => setIsPDPAModalOpen(true)}
                    />
                    <MenuButton
                        label="การตั้งค่าความเป็นส่วนตัว"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/7065/7065006.png"
                        onClick={() =>
                            Swal.fire(
                                "ยังไม่พร้อมใช้งาน",
                                "ฟังก์ชันนี้กำลังพัฒนา",
                                "info"
                            )
                        }
                    />
                    <MenuButton
                        label="การตั้งค่าความปลอดภัย"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/2592/2592317.png"
                        onClick={() =>
                            Swal.fire(
                                "ยังไม่พร้อมใช้งาน",
                                "ฟังก์ชันนี้กำลังพัฒนา",
                                "info"
                            )
                        }
                    />
                    <MenuButton
                        label="การตั้งค่าเพิ่มเติม"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/9195/9195918.png"
                        onClick={() =>
                            Swal.fire(
                                "ยังไม่พร้อมใช้งาน",
                                "ฟังก์ชันนี้กำลังพัฒนา",
                                "info"
                            )
                        }
                    />
                  
                </div>
            </div>
        </div>
    );
};

export default Settings;