import { motion, AnimatePresence } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { useContext, useState, useEffect } from "react"; // <-- Add useEffect here
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";
import Rating from "@mui/material/Rating";
import Linkify from "react-linkify";
import { GalleryContext } from "../../../../App";
import ShareModal from "./ShareModal";
import ContactModal from "./ContactModal";
import CommentModal from "./CommentModal"; // Import the CommentModal component
import ReportModal from "./ReportModal"; // Make sure to adjust the path accordingly

export default function ModalDetail({
  dataMainModal,
  id,
  heightStep,
  setHeightStep,
  statusComponent,
}) {
  const checkLogin = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  const { isGalleryOpen, setIsGalleryOpen } = useContext(GalleryContext);

  const menuModalPreview = [
    {
      name: "ติดต่อผู้ขาย",
      imgSrc: "https://cdn-icons-png.flaticon.com/512/16617/16617786.png",
      altText: "contact",
    },
    {
      name: "YouTube",
      imgSrc: "https://cdn-icons-png.flaticon.com/512/174/174883.png",
      altText: "youtube",
    },
    {
      name: "VR/3D",
      imgSrc: "https://cdn-icons-png.flaticon.com/512/10229/10229102.png",
      altText: "vr",
    },
    {
      name: "นำทาง",
      imgSrc: "https://cdn-icons-png.flaticon.com/512/2335/2335353.png",
      altText: "maps",
    },
    {
      name: "ถูกใจ",
      imgSrc: "   https://cdn-icons-png.flaticon.com/512/5735/5735325.png ",
      altText: "like",
    },
    {
      name: "แชร์ทรัพย์",
      imgSrc: "https://cdn-icons-png.flaticon.com/512/3925/3925154.png",
      altText: "share",
    },
    {
      name: "รายงานทรัพย์",
      imgSrc: "https://cdn-icons-png.flaticon.com/512/9019/9019234.png",
      altText: "report",
    },
    {
      name: "คอมเม้นท์",
      imgSrc: "https://cdn-icons-png.flaticon.com/512/2190/2190552.png",
      altText: "comment",
    },
  ];

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const openReportModal = () => {
    setIsReportModalOpen(true);
  };

  const closeReportModal = () => {
    setIsReportModalOpen(false);
  };

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [assetRating, setAssetRating] = useState(null);
  const [assetCommentCounter, setAssetCommentCounter] = useState(null);

  const fetchRating = async (assetId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/event/rating?asset_id=${assetId}`
      );
      setAssetRating(response.data.summary_stars);
      setAssetCommentCounter(response.data.total_comments);
    } catch (error) {
      console.error("Failed to fetch rating", error);
      setAssetRating(null);
      setAssetCommentCounter(null);
    }
  };

  useEffect(() => {
    if (dataMainModal.id) {
      fetchRating(dataMainModal.id);
    }
  }, [dataMainModal.id]);
  const handleCommentSubmit = async (commentData) => {
    const { rating, comment } = commentData;
    const token = await checkLogin(); // Store the token

    if (token !== undefined) {
      axios
        .post(
          `${process.env.REACT_APP_API}/event/write-comment`,
          {
            asset_id: dataMainModal.id,
            rating: rating,
            content: comment,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          Swal.fire("Success", "Your comment has been submitted!", "success");
        })
        .catch((err) => {
          const errMessage =
            err.response?.data?.Error || "Something went wrong";
          Swal.fire("", errMessage, "warning");
        });
    } else {
      const result = await Swal.fire({
        title: "กรุณา Login",
        html: `<p style="text-align: left; font-size: 16px;">คุณต้องเข้าสู่ระบบก่อนจึงจะสามารถแสดงความคิดเห็นได้</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Login",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#007BFF", // Blue color for the "Login" button
        cancelButtonColor: "#6c757d", // Gray color for the "Cancel" button
        customClass: {
          popup: "swal-left-align", // Apply a custom class to modify styles
        },
      });

      if (result.isConfirmed) {
        window.location.replace("/login"); // Redirect only if user clicks "Login"
      }
    }
  };

  const handleContactInfo = async (assetId) => {
    // Check if @ID_INVITER exists in localStorage
    const inviter = localStorage.getItem("@ID_INVITER");

    if (!inviter) {
      // If @ID_INVITER does not exist, check if "sharer" is in the URL
      const currentUrl = new URL(window.location.href);
      const sharerParam = currentUrl.searchParams.get("sharer");

      if (sharerParam) {
        try {
          // Make a request to convert uname to uid
          const response = await axios.post(
            `${process.env.REACT_APP_API}/event/convert-uname-uid`,
            { target_uid: sharerParam },
            {
              headers: {
                Authorization: `Bearer ${checkLogin()}`,
                Accept: "application/json, text/plain, */*",
                "Accept-Language": "en-US,en;q=0.9",
              },
            }
          );

          // Retrieve target_uname from response
          const targetUname = response.data.target_uname;

          if (targetUname) {
            // Set @ID_INVITER in localStorage
            localStorage.setItem("@ID_INVITER", targetUname);
          }
        } catch (error) {
          console.error("Error converting uname to uid:", error);
        }
      }
    }

    // Prepare the request payload
    const currentUrl = new URL(window.location.href);
    const sharerParam = currentUrl.searchParams.get("sharer");

    const payload = { assetId: assetId };
    if (sharerParam) {
      payload.sharer = sharerParam;
    }

    // Send the request to get contact info
    axios
      .post(`${process.env.REACT_APP_API}/event/getContact`, payload, {
        headers: {
          Authorization: `Bearer ${checkLogin()}`,
        },
      })
      .then((res) => {
        setContactInfo(res.data);
        setIsContactModalOpen(true);
      })
      .catch((err) => {
        const errMessage = err.response.data.Error;
        // Swal.fire("", `${errMessage}`, "warning");
        if (checkLogin() === undefined) {
          // Open a new window/tab to login
          window.open("/login?redirectBack=true", "_blank");
        }
      });
  };

  const handleShareAsset = (assetId) => {
    axios
      .post(
        `${process.env.REACT_APP_API}/mlm/share?aid=${assetId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${checkLogin()}`,
          },
        }
      )
      .then((res) => {
        setShareUrl(res.data.shareurl);
        setIsShareModalOpen(true);
      })
      .catch((err) => {
        const errMessage = err.response.data.Error;
        // Swal.fire("", `${errMessage}`, "warning");
        if (checkLogin() === undefined) {
          // เปิดหน้าต่างใหม่ไปยังหน้า login
          window.open("/login?redirectBack=true", "_blank"); // _blank สำหรับเปิดหน้าต่างหรือแท็บใหม่
        }
      });
  };

  function handleModalInfo(text, isYoutube, isVR_url) {
    const lat = dataMainModal.location.coordinates.lat;
    const lng = dataMainModal.location.coordinates.lng;

    switch (text) {
      case "youtube":
        if (isYoutube !== "") {
          window.open(`${dataMainModal.media.YoutubeURL}`);
        }
        break;
      case "vr":
        if (isVR_url !== "") {
          window.open(`${dataMainModal.media.vr_url}`);
        }
        break;

      case "maps":
        window.open(`https://www.google.com/maps?q=${lat},${lng}`);
        break;

      case "like":
        const token = localStorage.getItem("token");
        if (token) {
          handleAddToCart(dataMainModal.id);
        } else {
          window.location.replace("/login");
        }

        break;

      case "contact":
        sessionStorage.setItem("isClickContactModalDetail", true);
        handleContactInfo(dataMainModal.id);
        break;

      case "comment":
        console.log("Opening Comment Modal");
        setIsCommentModalOpen(true);
        break;

      case "report":
        openReportModal(); // Open the report modal

        // Swal.fire(``, `อยู่ในระหว่างการพัฒนา`, "info");
        break;

      case "share":
        handleShareAsset(dataMainModal.id);
        break;

      default:
        break;
    }
  }

  const handleAddToCart = (assetId) => {
    axios
      .post(
        `${process.env.REACT_APP_API}/event/addBasket?assetId=${assetId}`,
        {
          assetId: assetId,
        },
        {
          headers: {
            Authorization: `Bearer ${checkLogin()}`,
          },
        }
      )
      .then((res) => {
        const msg = res.data.message;
        Swal.fire("สำเร็จ", `${msg}`, "success");
      })
      .catch((err) => {
        const errMessage = err.response.data.Error;
        Swal.fire("", `${errMessage}`, "warning");
        if (checkLogin() === undefined) {
          window.location.replace("/login");
        }
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const heightMap = {
    hide: "0px",
    min: "12vh",
    half: "60vh",
    full: "92vh",
  };

  const openModal = (image, index) => {
    setIsGalleryOpen(true);

    const isDesktop = window.innerWidth > 768;
    if (isDesktop) {
      // nothing
    } else {
      setSelectedImage(image);
      setIsModalOpen(true);
    }
    setCurrentImageIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  const formatPrice = (price) => {
    if (price >= 1000000) {
      return `${(price / 1000000).toFixed(2)} ล้านบาท`;
    } else {
      return `${price.toLocaleString()} บาท`;
    }
  };

  const [isDraggingFromScrollArea, setIsDraggingFromScrollArea] =
    useState(false);

  const handleDragEnd = (e, info) => {
    if (isDraggingFromScrollArea) {
      // Reset the flag and do not proceed with height change
      setIsDraggingFromScrollArea(false);
      return;
    }

    const currentUrl = new URL(window.location.href);
    const verticalThreshold = 5;
    const verticalMovement = Math.abs(info.offset.y);

    if (verticalMovement > verticalThreshold) {
      let positionY = info.point.y;
      const windowHeight = window.innerHeight;

      if (positionY < windowHeight / 4) {
        setHeightStep("full");
        currentUrl.searchParams.set("card", "full");
      } else if (positionY < windowHeight * 0.65) {
        setHeightStep("half");
        currentUrl.searchParams.set("card", "half");
      } else {
        setHeightStep("min");
        currentUrl.searchParams.set("card", "min");
      }

      // Update the browser's URL without reloading the page
      window.history.pushState({}, "", currentUrl);
    }
  };

  const swipeCardHandler = useSwipeable({
    onSwipedUp: () => setHeightStep("full"),
    onSwipedDown: () => setHeightStep("min"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const goToPreviousImage = () => {
    const newIndex =
      (currentImageIndex - 1 + dataMainModal.images_url.AssetImagesURL.length) %
      dataMainModal.images_url.AssetImagesURL.length;
    setCurrentImageIndex(newIndex);
  };

  const [divHeight, setDivHeight] = useState("300px"); // Default height

  useEffect(() => {
    const textLength = dataMainModal.description.length;
    let calculatedHeight;

    if (textLength > 300) {
      calculatedHeight = "520px";
    } else if (textLength < 100) {
      calculatedHeight = "100px";
    } else {
      // Calculate height proportionally between 500 and 800 characters
      calculatedHeight = `${300 + (textLength - 500) * (500 / 300)}px`;
    }

    setDivHeight(calculatedHeight);
  }, [dataMainModal.description]);

  const fadeTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 1.2 },
  };

  const goToNextImage = () => {
    const newIndex =
      (currentImageIndex + 1) % dataMainModal.images_url.AssetImagesURL.length;
    setCurrentImageIndex(newIndex);
  };

  const totalImages = dataMainModal.images_url.AssetImagesURL.length;
  const [swipeDirection, setSwipeDirection] = useState(null);

  const gallerySwipeHandler = useSwipeable({
    onSwipedLeft: () => {
      setSwipeDirection("left");
      goToNextImage();
    },
    onSwipedRight: () => {
      setSwipeDirection("right");
      goToPreviousImage();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const fadeInImagesTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: {
      duration: 1.5,
      delay: 0.3, // Add a 0.2 second delay before starting the animation
    },
  };

  return (
    <>
      <motion.main
        className={`z-40 w-full h-full bottom-10 bg-white rounded-t-2xl shadow-lg border ${
          statusComponent === "map" ? "absolute" : "fixed"
        }`}
        initial={{ y: "100%" }}
        animate={{
          height: heightMap[heightStep],
          y: 0,
        }}
        transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }} // Smoother animation with easing
        // drag="y"
        // dragConstraints={{ top: 0, bottom: 0 }}
        // dragElastic={0.2} // More elastic drag
        // onDragEnd={handleDragEnd}
        // onDragStart={() => setIsDraggingFromScrollArea(false)} // Reset on drag start
        // {...swipeCardHandler} // Spread the swipe handlers here
      >
        <CommentModal
          isOpen={isCommentModalOpen}
          onRequestClose={() => setIsCommentModalOpen(false)}
          onSubmit={handleCommentSubmit}
          assetId={dataMainModal.id}
        />

        <ShareModal
          isOpen={isShareModalOpen}
          onRequestClose={() => setIsShareModalOpen(false)}
          shareUrl={shareUrl}
        />

        <ReportModal
          isOpen={isReportModalOpen}
          onRequestClose={closeReportModal}
          assetId={dataMainModal.id}
        />

        <ContactModal
          isOpen={isContactModalOpen}
          onRequestClose={() => setIsContactModalOpen(false)}
          contactInfo={contactInfo}
        />

        {dataMainModal.imperfect_asset && dataMainModal.data.editable && (
          <motion.button
            className="absolute top-10 right-5 z-50 bg-blue-600 text-white px-4 py-2 rounded-full shadow-lg hover:bg-blue-700"
            onClick={() => {
              // Redirect to the edit page using react-router Link or programmatic navigation
              window.location.href = `/edit/lead/${dataMainModal.id}`;
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            แก้ไขประกาศ
          </motion.button>
        )}

        {heightStep === "full" && (
          <motion.button
            className="absolute top-24 left-1/2 transform -translate-x-1/2 z-50 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700"
            onClick={() => {
              setHeightStep("half");
              const currentUrl = new URL(window.location.href);
              console.log("card half");
              currentUrl.searchParams.set("card", "half");
              // Use window.history.pushState to update the browser URL
              window.history.pushState({}, "", currentUrl.toString());
            }}
            initial={fadeTransition.initial}
            animate={fadeTransition.animate}
            exit={fadeTransition.exit}
            transition={fadeTransition.transition}
            style={{ marginTop: "-16px" }} // Move the entire div up by 16px
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/339/339145.png"
              alt="Minimize"
              className="w-8 h-8"
            />
          </motion.button>
        )}

        {heightStep === "half" && (
          <motion.div
            className="absolute -top-2 left-1/2 transform -translate-x-1/2 z-50 flex space-x-2"
            initial={fadeTransition.initial}
            animate={fadeTransition.animate}
            exit={fadeTransition.exit}
            transition={fadeTransition.transition}
            style={{ marginTop: "-16px" }} // Move the entire div up by 16px
          >
            <button
              className="bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700"
              onClick={() => {
                setHeightStep("full");
                const currentUrl = new URL(window.location.href);
                console.log("card full");
                currentUrl.searchParams.set("card", "full");
                // Use window.history.pushState to update the browser URL
                window.history.pushState({}, "", currentUrl.toString());
              }}
              aria-label="Expand"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/992/992534.png"
                alt="Expand"
                className="w-8 h-8"
              />
            </button>
            <button
              className="bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700"
              onClick={() => {
                setHeightStep("min");
                const currentUrl = new URL(window.location.href);
                console.log("card min");
                currentUrl.searchParams.set("card", "min");
                // Use window.history.pushState to update the browser URL
                window.history.pushState({}, "", currentUrl.toString());
              }}
              aria-label="Minimize"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/339/339145.png"
                alt="Minimize"
                className="w-8 h-8"
              />
            </button>
          </motion.div>
        )}

        {heightStep === "min" && (
          <motion.button
            className="absolute -top-4 left-1/2 transform -translate-x-1/2 z-50 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700"
            onClick={() => {
              setHeightStep("half");
              const currentUrl = new URL(window.location.href);
              console.log("card half");
              currentUrl.searchParams.set("card", "half");
              // Use window.history.pushState to update the browser URL
              window.history.pushState({}, "", currentUrl.toString());
            }}
            initial={fadeTransition.initial}
            animate={fadeTransition.animate}
            exit={fadeTransition.exit}
            transition={fadeTransition.transition}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/992/992534.png"
              alt="Expand"
              className="w-8 h-8"
            />
          </motion.button>
        )}
        <div className="h-[6px] w-12 absolute left-1/2 transform -translate-x-1/2 top-2 bg-gray-400 rounded-full"></div>
        <div className="flex flex-col w-full h-full mt-2.5">
          <div
            className={`relative w-full h-29 p-4 ${
              dataMainModal.sold
                ? "bg-red-500"
                : "bg-gradient-to-r from-blue-500 to-indigo-600"
            } text-white rounded-t-2xl shadow-md`}
          >
            <h1
              className={`text-xl font-bold ${
                dataMainModal.sold ? "text-yellow-400" : ""
              }`}
            >
              {dataMainModal.sold
                ? "ทรัพย์นี้ได้ขายออกไปแล้ว"
                : dataMainModal.title.slice(0, 43)}
            </h1>
            <h3 className="text-sm">{dataMainModal.id}</h3>
            <h4 className="text-xl font-semibold bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 bg-clip-text text-transparent">
              {dataMainModal.sold
                ? "" // ขายแล้วราคาจะไม่โชว์
                : dataMainModal.sell_price
                ? formatPrice(dataMainModal.sell_price)
                : dataMainModal.rent_price
                ? formatPrice(dataMainModal.rent_price)
                : dataMainModal.sellout_price
                ? formatPrice(dataMainModal.sellout_price)
                : "ราคาติดต่อผู้ขาย"}
            </h4>

            {assetRating !== null && assetCommentCounter !== null && (
              <div className="flex items-center mt-2">
                {/* Rating and Comment Count */}
                {dataMainModal.allowcomment && (
                  <div className="flex items-center">
                    <Rating name="read-only" value={assetRating} readOnly />
                    <span className="ml-2 text-white">{assetRating} ดาว</span>
                  </div>
                )}

                {/* Conditionally render comment section */}
                {dataMainModal.allowcomment ? (
                  <span
                    className="text-sm cursor-pointer ml-auto text-white text-xl" // Positioned to the right
                    onClick={() => setIsCommentModalOpen(true)} // Opens the modal on click
                  >
                    ({assetCommentCounter}){" "}
                    <span className="text-white-500 underline">คอมเม้นท์</span>{" "}
                    {/* Blue and underlined */}
                  </span>
                ) : (
                  <span className="text-white-500 underline">
                    ทรัพย์ดังกล่าวได้ปิดความคิดเห็น
                  </span>
                )}
              </div>
            )}
          </div>

          <div className="flex bg-white min-h-12 max-h-12 overflow-x-auto space-x-2.5 w-full mx-auto p-2">
            {menuModalPreview.map((data, index) => {
              const youtube = dataMainModal.media.YoutubeURL;
              const vr_url = dataMainModal.media.vr_url;
              const isReport = data.name === "รายงานทรัพย์"; // Identify if it's the Report option

              const isYouTubeDisabled =
                youtube === "" && data.name === "YouTube";
              const isVRDisabled = vr_url === "" && data.name === "VR/3D";

              const disabledClass = "opacity-50 pointer-events-none";

              // Determine background color for the "share" button
              const shareBackgroundClass = dataMainModal.official
                ? "bg-green-400 hover:bg-green-600"
                : "bg-yellow-500 hover:bg-yellow-600";

              // Apply the disabled class only if it's not the Report option
              const classNames =
                !isReport && (isYouTubeDisabled || isVRDisabled)
                  ? `${disabledClass}`
                  : data.name === "แชร์ทรัพย์"
                  ? `hidden ${shareBackgroundClass} cursor-pointer`
                  : "bg-gray-100 hover:bg-gray-200 cursor-pointer";

              return (
                <div
                  key={index}
                  onClick={() =>
                    !isYouTubeDisabled &&
                    !isVRDisabled &&
                    handleModalInfo(data.altText, youtube, vr_url)
                  }
                  className={`flex justify-center items-center flex-shrink-0 w-auto p-2.5 h-full border rounded-full gap-x-2.5 ${classNames} ${
                    isReport ? "cursor-pointer" : ""
                  }`}
                >
                  <img
                    className="w-8 h-8"
                    src={data.imgSrc}
                    alt={data.altText}
                  />
                  <h1 className="text-sm">{data.name}</h1>
                </div>
              );
            })}
          </div>

          {dataMainModal.type_post &&
            dataMainModal.type_post.Type.length > 0 && (
              <div className="absolute -top-2 left-10 transform -translate-x-1/2 z-50">
                <div className="flex justify-center items-center">
                  {dataMainModal.type_post.Type.map((type, index) => (
                    <span
                      key={index}
                      className="bg-blue-500 text-white px-3 py-1 rounded-full mx-1 text-md font-semibold shadow-lg"
                    >
                      {type}
                    </span>
                  ))}
                </div>
              </div>
            )}

          <div className="absolute -top-2 right-[-60px] transform -translate-x-1/2 z-50">
            <div className="w-[130px] flex justify-center items-center">
              <span
                onClick={() => handleShareAsset(dataMainModal.id)}
                className={`flex h-8 justify-center items-center text-black w-full py-1 rounded-full  text-md font-semibold shadow-lg ${
                  dataMainModal.official
                    ? "bg-green-400 hover:bg-green-600 text-white"
                    : "bg-yellow-500 hover:bg-yellow-600"
                }`}
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3925/3925154.png"
                  alt="Minimize"
                  className="w-4 h-4"
                />
                <h1 className="ml-2.5">แชร์ทรัพย์</h1>
              </span>
            </div>
          </div>

          <motion.div
            className={`flex gap-x-2.5 w-11/12 mx-auto overflow-x-auto rounded-xl p-2 ${
              heightStep === "half" ? "h-full" : ""
            }`}
            style={{
              height:
                heightStep === "full"
                  ? dataMainModal.images_url.AssetImagesURL.length === 1
                    ? "44vh"
                    : "27vh"
                  : "50vh",
            }}
            key={heightStep} // This triggers the re-render and animation on heightStep change
            initial={fadeInImagesTransition.initial}
            animate={fadeInImagesTransition.animate}
            transition={fadeInImagesTransition.transition}
          >
            {dataMainModal.images_url.AssetImagesURL.map((image, index) => (
              <img
                className={`mx-auto cursor-pointer rounded-lg shadow-md ${
                  heightStep === "half" || heightStep === "full"
                    ? "h-full w-full object-cover"
                    : "h-32"
                }`}
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                onClick={() => openModal(image, index)}
              />
            ))}
          </motion.div>

          <div
            className="border mt-2.5 rounded-xl overflow-y-auto w-11/12 mx-auto p-4 bg-white shadow-lg whitespace-pre-wrap text-gray-800"
            style={{ height: divHeight }}
            onWheel={(e) => e.stopPropagation()} // Stop wheel events from affecting the modal drag
            onTouchStart={(e) => {
              e.stopPropagation();
              setIsDraggingFromScrollArea(true); // Set dragging from scroll area
            }}
            onTouchMove={(e) => e.stopPropagation()} // Stop touch move events from affecting the modal drag
            onMouseDown={(e) => {
              e.stopPropagation();
              setIsDraggingFromScrollArea(true); // Set dragging from scroll area
            }}
            onDragStart={(e) => {
              e.stopPropagation();
              setIsDraggingFromScrollArea(true); // Set dragging from scroll area
            }}
            onDrag={(e) => e.stopPropagation()} // Prevent drag events in this area
          >
            <Linkify
              componentDecorator={(href, text, key) => (
                <a
                  href={href}
                  key={key}
                  style={{ color: "#1e90ff", textDecoration: "underline" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </a>
              )}
            >
              {dataMainModal.description}
            </Linkify>
          </div>
        </div>
      </motion.main>

      {isGalleryOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div
            {...gallerySwipeHandler}
            className="relative flex flex-col justify-center items-center w-full h-full"
          >
            <button
              className="absolute left-0 ml-4 text-white bg-gray-800 hover:bg-gray-900 rounded-full px-4 py-2 z-10"
              onClick={() => {
                setSwipeDirection("right");
                goToPreviousImage();
              }}
            >
              ย้อน
            </button>
            <AnimatePresence initial={false}>
              <motion.div
                key={currentImageIndex}
                className="absolute"
                initial={{
                  opacity: 0,
                  x: swipeDirection === "left" ? 100 : -100,
                }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: swipeDirection === "left" ? -100 : 100 }}
                transition={{ duration: 0.8 }}
              >
                <img
                  src={
                    dataMainModal.images_url.AssetImagesURL[currentImageIndex]
                  }
                  alt={`Image ${currentImageIndex + 1}`}
                  className="max-w-full max-h-full"
                />
                <div className="absolute text-white flex justify-center items-center w-full">
                  ภาพที่ {currentImageIndex + 1} จาก {totalImages}
                </div>
              </motion.div>
            </AnimatePresence>
            <div className="absolute bottom-20 flex space-x-2 w-full justify-center">
              {dataMainModal.images_url.AssetImagesURL.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full ${
                    index === currentImageIndex ? "bg-white" : "bg-gray-600"
                  }`}
                ></div>
              ))}
            </div>

            <button
              className="absolute right-0 mr-4 text-white bg-gray-800 hover:bg-gray-900 rounded-full px-4 py-2 z-10"
              onClick={() => {
                setSwipeDirection("left");
                goToNextImage();
              }}
            >
              ถัดไป
            </button>
            <button
              className="absolute top-5 right-5 text-white bg-red-500 hover:bg-red-700 rounded-full px-4 py-2 z-10"
              onClick={closeGallery}
            >
              ปิดเมนู
            </button>
          </div>
        </div>
      )}
    </>
  );
}
