import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";
import Snowfall from 'react-snowfall';
import { motion } from "framer-motion";

const RegisterNew = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [statusPage, setStatusPage] = useState(1);
  const [statusConfirmIDInviter, setStatusConfirmIDInviter] = useState(false);
  const [provinceSelected, setProvinceSelected] = useState(null);
  const [amphureSelected, setAmphureSelected] = useState(null);
  const [tambonSelected, setTambonSelected] = useState("");
  const ShowPasswordIcon = "https://cdn-icons-png.flaticon.com/512/159/159604.png";
  const HidePasswordIcon = "https://cdn-icons-png.flaticon.com/512/2710/2710718.png";

  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [idFromURLRegister, setIdFromURLRegister] = useState(null);

  useEffect(() => {
    const pageFromUrl = searchParams.get("statusPage");
    if (pageFromUrl) {
      setStatusPage(Number(pageFromUrl));
    }
    getIdFromUrl();
    getIdFromLocalStorage();
    loadInputDataFromCookies();
  }, [searchParams]);

  const getIdFromUrl = async () => {
    const ID_Invite = searchParams.get("ID");
    const IDLocal = localStorage.getItem("@ID_INVITER");
    const USER_HAVE_INVITER_FROM_URL_BEFORE = localStorage.getItem("@HAVE_INVITER_URL");
    if (IDLocal === null || IDLocal !== ID_Invite) {
      if (ID_Invite !== null && USER_HAVE_INVITER_FROM_URL_BEFORE !== "true") {
        localStorage.setItem("@ID_INVITER", ID_Invite);
      } else {
        console.log("Not Found ID");
      }
    }
  };

  const getIdFromLocalStorage = () => {
    const IDLocal = localStorage.getItem("@ID_INVITER");
    if (IDLocal !== null) {
      setIdFromURLRegister(IDLocal);
      setUpload({ ...upload, inviter_uname: IDLocal });
    }
  };

  const [upload, setUpload] = useState({
    phone_number: "",
    otp: "",
    check_phoneNumber: "",
    uname: "",
    password: "",
    confirmPassword: "",
    email_address: "",
    firstName: "",
    lastName: "",
    gender: "",
    age: "",
    role: "",
    inviter_uname: "",
    zipcode: null,
    facebookUrl: "",
    lineId: "",
    role: "default",
  });

  const {
    otp,
    check_phoneNumber,
    uname,
    password,
    confirmPassword,
    gender,
    email_address,
    firstName,
    lastName,
    age,
    role,
    inviter_uname,
    zipcode,
    facebookUrl,
    lineId,
  } = upload;

  const inputValue = (name) => (e) => {
    const newValue = { ...upload, [name]: e.target.value };
    setUpload(newValue);
    saveInputDataToCookies(newValue);
    if (name === "password") {
      setPasswordStrength(calculatePasswordStrength(e.target.value));
    }
  };

  const loadInputDataFromCookies = () => {
    const savedData = Cookies.get("registerInputData");
    if (savedData) {
      setUpload(JSON.parse(savedData));
    }
  };

  const saveInputDataToCookies = (data) => {
    Cookies.set("registerInputData", JSON.stringify(data), { expires: 3 / 24 });
  };

  const [textCheckPhoneNumber, setTextCheckPhoneNumber] = useState("กรุณากรอกเบอร์โทรศัพท์ 10 หลัก");

  const checkPhoneNumber = (number) => {
    if (number !== "" && number.length === 10) {
      axios
        .post(`${process.env.REACT_APP_API}/user/checkPhoneNumber`, { checkPhoneNumber: number })
        .then((res) => {
          if (res.data.Status === true) {
            setTextCheckPhoneNumber("เบอร์นี้สมัครสมาชิกแล้ว");
          } else {
            setTextCheckPhoneNumber("เบอร์นี้สามารถใช้งานได้");
          }
        })
        .catch((err) => {});
    }
    if (number.length !== 10) {
      setTextCheckPhoneNumber("กรุณากรอกเบอร์โทร 10 หลัก");
    }
  };

  const registerConfirmStep = (e) => {
    e.preventDefault();
    if (statusConfirmPolicy === false) {
      Swal.fire("", "กรุณายอมรับข้อตกลงการใช้งานเว็บไซต์ก่อนสมัครเป็นสมาชิก", "warning");
    } else if (password !== confirmPassword) {
      Swal.fire("", "รหัสผ่านกับยืนยันรหัสผ่านไม่ตรงกัน", "warning");
    } else if (password.length < 8) {
      Swal.fire("", "รหัสผ่านขั้นต่ำ 8 ตัว", "warning");
    } else if (statusConfirmPolicy && password === confirmPassword) {
      axios
        .post(`${process.env.REACT_APP_API}/auth/register`, {
          uname: uname,
          password: password,
          email_address: email_address,
          firstName: firstName === "" ? "ไม่ระบุ" : firstName,
          lastName: lastName === "" ? "ตัวตน" : lastName,
          age: age,
          role: role,
          phone_number: check_phoneNumber,
          inviter_uname: inviter_uname === "" ? "Manday" : inviter_uname,
          zipcode: zipcode,
          province: provinceSelected,
          amphure: amphureSelected,
          tambol: tambonSelected,
        })
        .then(async (res) => {
          await Swal.fire({
            icon: "success",
            title: "สำเร็จ",
            text: `${res.data.Success}`,
          });
          setStatusPage(statusPage + 1);
          setSearchParams({ statusPage: statusPage + 1 });
        })
        .catch((err) => {
          countFailedReg();
          const errorMessage = err.response.data.Error;
          Swal.fire({
            icon: "error",
            title: "ไม่สำเร็จ",
            text: `${errorMessage}`,
          });
        });
    }
  };

  function countFailedReg() {
    axios
      .post(`${process.env.REACT_APP_ADMIN_API}/event/count-try-reg`, {})
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function calculatePasswordStrength(password) {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/[0-9]+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;
    switch (strength) {
      case 0:
        return "";
      case 1:
      case 2:
        return "ไม่ปลอดภัย";
      case 3:
      case 4:
        return "ปานกลาง";
      case 5:
        return "ปลอดภัยสูง";
      default:
        return "";
    }
  }

  function validateEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const allowedDomains = ["@gmail.com", "@hotmail.com", "@yahoo.com", "@outlook.com", "@icloud.com"];
    const domain = email_address.slice(email_address.indexOf("@"));

    if (email_address.length >= 80) {
      Swal.fire("ข้อมูลไม่ถูกต้อง", "Email address ต้องมีตัวอักขระน้อยกว่า 80 ตัวอักษร", "warning");
      return false;
    } else if (!emailRegex.test(email_address)) {
      Swal.fire("ข้อมูลไม่ถูกต้อง", "รูปแบบอีเมลไม่ถูกต้อง", "warning");
      return false;
    } else if (!allowedDomains.includes(domain)) {
      Swal.fire("ข้อมูลไม่ถูกต้อง", "อีเมลที่รองรับ Gmail, Hotmail, Yahoo, และ Outlook ", "warning");
      return false;
    }
    return true;
  }

  function checkConfirmStepTwotoStepThree() {
    if (uname === "") {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณาระบุ ID ของท่าน", "warning");
    } else if (password === "") {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณาใส่รหัสผ่าน", "warning");
    } else if (confirmPassword === "") {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณายืนยันรหัสผ่าน", "warning");
    } else if (password !== confirmPassword) {
      Swal.fire("ข้อมูลไม่ถูกต้อง", "รหัสผ่านไม่ตรงกัน", "warning");
    } else if (age === "") {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณาระบุอายุ", "warning");
    } else if (validateEmail() === false) {
      Swal.fire("ข้อมูลไม่ถูกต้อง", "รูปแบบอีเมลไม่ถูกต้อง", "warning");
    } else {
      setStatusPage(statusPage + 1);
      setSearchParams({ statusPage: statusPage + 1 });
    }
  }

  const getToken = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  const setCookies = async (token) => {
    await Cookies.set("jwt", token);
    await getToken();
    window.location.replace("/home");
  };

  const otpConfirm = (e) => {
    e.preventDefault();
    setTimeout(() => {
      axios
        .post(`${process.env.REACT_APP_API}/auth/verify-otp`, {
          phone_number: check_phoneNumber,
          otp: otp,
        })
        .then((res) => {
          const token = res.data.token;
          Swal.fire({
            icon: "success",
            title: "สำเร็จ",
            text: `กำลังพาคุณไปหน้าหลัก`,
          });
          setCookies(token);
        })
        .catch((err) => {
          const ErrorMessage = err.response.data.Error;
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: `${ErrorMessage}`,
          });
          setUpload({ ...upload, otp: "" });
        });
    }, 100);
  };

  const crossCheckPhoneNumber = () => {
    if (statusPage === 1) {
      if (check_phoneNumber.length !== 10) {
        Swal.fire("รูปแบบเบอร์ไม่ถูกต้อง", "กรุณากรอกให้ครบ 10 หลัก", "error");
      } else {
        if (check_phoneNumber.startsWith("0")) {
          if (textCheckPhoneNumber === "เบอร์นี้สามารถใช้งานได้") {
            setStatusPage(statusPage + 1);
            setSearchParams({ statusPage: statusPage + 1 });
          } else {
            Swal.fire("ไม่สำเร็จ", "เบอร์นี้สมัครสมาชิกแล้ว", "error");
          }
        }
      }
    }
  };

  const [dataInviterUnameFromAPI, setDataInviterUnameFromAPI] = useState([]);

  const inviter_uname_search = (keyword) => {
    axios
      .post(`${process.env.REACT_APP_API}/user/fetch-uname?uname=${keyword}`, { keyword })
      .then((res) => {
        setDataInviterUnameFromAPI(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [uNameFromUserSelected, setUnameFromUserSelected] = useState(null);
  function handleSelectedInviter(e) {
    const uname = e.target.getAttribute("data-uname");
    setUpload({ ...upload, inviter_uname: uname });
    setDataInviterUnameFromAPI([]);
  }

  const [genderSelected, setGenderSelected] = useState("");
  const [roleSelected, setRoleSelected] = useState("");
  const genderHandle = (gender) => {
    setGenderSelected(gender);
    setUpload({ ...upload, gender: gender });
  };

  const roleHandel = (role) => {
    setRoleSelected(role);
    setUpload({ ...upload, role: role });
  };

  const callZipcode = async (zipcodeRaw) => {
    if (zipcodeRaw.length === 5) {
      axios
        .post(`${process.env.REACT_APP_API}/fetchData/getQueryLocation`, { zipcode: zipcodeRaw })
        .then((res) => {
          setAddressData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = otp.split("");
      newOtp[index] = value;
      const newOtpStr = newOtp.join("");
      setUpload({ ...upload, otp: newOtpStr });

      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }

      if (newOtpStr.length === 6 && newOtpStr.split("").every((num) => num !== "")) {
        axios
          .post(`${process.env.REACT_APP_API}/auth/verify-otp`, {
            phone_number: check_phoneNumber,
            otp: newOtpStr,
          })
          .then((res) => {
            const token = res.data.token;
            Swal.fire({
              icon: "success",
              title: "สำเร็จ",
              text: `กำลังพาคุณไปหน้าหลัก`,
            });
            setCookies(token);
          })
          .catch((err) => {
            const ErrorMessage = err.response.data.Error;
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: `${ErrorMessage}`,
            });
            setUpload({ ...upload, otp: "" });
          });
      }
    }
  };

  const [addressData, setAddressData] = useState([]);
  const provinceArr = [];
  const [provinceData, setProvinceData] = useState([]);
  const DistrictsArr = [];
  const [districtsData, setdistrictsData] = useState([]);
  const TambonArr = [];
  const [TambonData, setTambonData] = useState([]);

  useEffect(() => {
    if (addressData !== "") {
      addressData.map((data) => {
        const provinceCheck = data.amphure.province.name_th;
        const DistrictCheck = data.amphure.name_th;
        const TambonCheck = data.name_th;
        if (provinceArr.indexOf(provinceCheck) === -1) {
          const province = data.amphure.province.name_th;
          setProvinceSelected(province);
          provinceArr.push(province);
        }

        if (DistrictsArr.indexOf(DistrictCheck) === -1) {
          const district = data.amphure.name_th;
          setAmphureSelected(district);
          DistrictsArr.push(district);
        }

        if (TambonArr.indexOf(TambonCheck) === -1) {
          const tambon = data.name_th;
          TambonArr.push(tambon);
        }
      });
    }
    setProvinceData(provinceArr);
    setdistrictsData(DistrictsArr);
    setTambonData(TambonArr);
  }, [addressData]);

  const handleSetTambon = (e) => {
    const tambonFromUser = e.target.value;
    setTambonSelected(tambonFromUser);
  };

  const [statusConfirmPolicy, setStatusConfirmPolicy] = useState(false);
  const [popupPolicy, setPopupPolicy] = useState(false);

  function handlePolicyButton() {
    setStatusConfirmPolicy(!statusConfirmPolicy);
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function handlePopupPolicy() {
    scrollToTop();
    setPopupPolicy(true);
  }

  function handleConfirmPolicy() {
    setPopupPolicy(false);
    setStatusConfirmPolicy(true);
  }

  const policyText = `ข้อตกลงการใช้งานเว็บไซต์ Postperty.com 
1. ขอบเขตของข้อตกลง ข้อตกลงนี้กำหนดเงื่อนไขการใช้บริการของเว็บไซต์ Postperty.com (“เว็บไซต์”) ซึ่งเป็นแพลตฟอร์มสำหรับการซื้อ-ขายและโฆษณาอสังหาริมทรัพย์ ผู้ใช้บริการ (“ผู้ใช้”) ต้องอ่านและยอมรับเงื่อนไขก่อนใช้งานเว็บไซต์ 

2. การสมัครสมาชิกและการใช้งาน 
2.1 ผู้ใช้ต้องให้ข้อมูลที่ถูกต้องและเป็นจริงเมื่อสมัครสมาชิก 
2.2 ผู้ใช้เป็นผู้รับผิดชอบต่อบัญชีของตนเอง และต้องรักษาข้อมูลเข้าสู่ระบบเป็นความลับ 
2.3 ผู้ใช้ตกลงที่จะไม่ใช้เว็บไซต์เพื่อกิจกรรมที่ผิดกฎหมาย หรือขัดต่อศีลธรรมอันดี 
2.4 ผู้ใช้ 1คน มีสิทธิ์สมัครได้แค่ 1 ID เท่านั้น หาก ตรวจพบว่ามีการ สมัคร มากกว่า 1 ID บริษัท (เว็บไซต์) ขอสงวนสิทธิ์ ในการ ตัดสิทธิ ประโยชน์ ของID ที่ซ้ำ โดยไม่สามารถ โอนย้าย สิทธิประโยชน์ ระหว่าง ID ได้

3. ขอบเขตความรับผิดชอบของเว็บไซต์ 
3.1 เว็บไซต์ทำหน้าที่เป็นสื่อกลางระหว่างผู้ซื้อและผู้ขายอสังหาริมทรัพย์เท่านั้น และไม่รับประกันความถูกต้องของข้อมูลที่ผู้ใช้โพสต์ 
3.2 เว็บไซต์ไม่รับผิดชอบต่อความเสียหายใด ๆ ที่เกิดขึ้นจากการซื้อ-ขาย หรือข้อตกลงที่เกิดขึ้นระหว่างผู้ใช้ 
3.3 เว็บไซต์มีสิทธิ์แก้ไข ปรับปรุง หรือยุติการให้บริการบางส่วนหรือทั้งหมดได้โดยไม่ต้องแจ้งล่วงหน้า 

4. ข้อกำหนดเกี่ยวกับการลงประกาศ 
4.1 ผู้ใช้ต้องรับผิดชอบต่อข้อมูลที่โพสต์ลงในเว็บไซต์ และต้องเป็นข้อมูลที่ถูกต้อง ไม่เป็นเท็จหรือทำให้ผู้อื่นเข้าใจผิด 
4.2 ห้ามลงประกาศที่ละเมิดกฎหมาย ละเมิดลิขสิทธิ์ หรือมีเนื้อหาที่ไม่เหมาะสม 
4.3 เว็บไซต์มีสิทธิ์ลบหรือระงับประกาศใด ๆ ที่ฝ่าฝืนเงื่อนไขการให้บริการโดยไม่ต้องแจ้งให้ทราบล่วงหน้า

5. ค่าธรรมเนียมและการชำระเงิน 
5.1 การใช้บริการบางประเภทบนเว็บไซต์อาจมีค่าธรรมเนียม ซึ่งจะแจ้งให้ทราบก่อนการใช้งาน 
5.2 ค่าธรรมเนียมที่ชำระไปแล้วจะไม่สามารถขอคืนได้ ยกเว้นในกรณีที่เว็บไซต์เป็นฝ่ายยกเลิกบริการเอง 
5.3 การจ่ายค่าตอบแทน ในรูปแบบค่า คอมมิชชั่น ให้แก่สมาชิก เวปไซต์ จะจ่ายให้ แก่สมาชิก ที่ ยืนยันตัวตนแล้ว เท่านั้น และจะต้องถูกหักภาษี ณ ที่จ่าย ตามอัตราที่กฎหมายกำหนด 
5.4 การคำนวณ การจ่ายค่าคอมมิชชั่น อาจเปลี่ยนแปลงได้ในภายหลัง 
5.5 การจ่ายค่าคอมมิชชั่น จะเกิดขึ้น หลังจาก บริษัท ได้รับค่าคอมมิชชั่น จากลูกค้าแล้ว เท่านั้น 

6. การยุติการใช้งานบัญชี 
6.1 เว็บไซต์มีสิทธิ์ระงับหรือยกเลิกบัญชีของผู้ใช้ หากพบว่ามีการละเมิดเงื่อนไขการให้บริการ 
6.2 ผู้ใช้สามารถยกเลิกบัญชีของตนเองได้โดยติดต่อฝ่ายบริการลูกค้าของเว็บไซต์ Line OA : @postperty

7. การแก้ไขข้อตกลง 
7.1 เว็บไซต์มีสิทธิ์แก้ไขเงื่อนไขการให้บริการได้ตลอดเวลา โดยจะแจ้งให้ผู้ใช้ทราบผ่านทางเว็บไซต์ 
7.2 การใช้งานเว็บไซต์หลังจากมีการแก้ไขข้อตกลงถือว่าผู้ใช้ยอมรับเงื่อนไขใหม่ 

8. กฎหมายที่ใช้บังคับ 
ข้อตกลงนี้อยู่ภายใต้กฎหมายของประเทศไทย หากมีข้อพิพาทเกิดขึ้น ให้ใช้ศาลที่มีเขตอำนาจในประเทศไทยเป็นผู้พิจารณา`;

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 flex flex-col justify-center items-center">
      {statusPage === 1 && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-11/12 md:w-96 bg-white shadow-lg rounded-xl p-8"
        >
          <form className="flex flex-col items-center">
            <h1 className="text-3xl font-semibold mb-6">สมัครสมาชิก</h1>
            <h2 className="text-xl mb-4">Postperty</h2>
            <input
              value={check_phoneNumber}
              type="text"
              placeholder="เบอร์โทรศัพท์"
              onChange={(e) => {
                inputValue("check_phoneNumber")(e);
                checkPhoneNumber(e.target.value);
              }}
              className="w-full h-12 rounded-full px-4 py-2 mb-3 border-2 border-gray-300 focus:border-blue-500 focus:outline-none text-center"
            />
            <p
              className={`${textCheckPhoneNumber === "เบอร์นี้สามารถใช้งานได้"
                ? "text-green-500"
                : textCheckPhoneNumber === "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
                ? "text-black opacity-40"
                : "text-red-500"
              } mb-3`}
            >
              {textCheckPhoneNumber}
            </p>
            <button
              type="button"
              onClick={crossCheckPhoneNumber}
              className="bg-blue-500 text-white w-32 h-12 rounded-full mt-4 mb-4 hover:bg-blue-600 transition-colors"
            >
              ถัดไป
            </button>
          </form>
        </motion.div>
      )}
      {statusPage === 2 && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-11/12 md:w-3/6 bg-white shadow-lg rounded-xl p-8 mt-10"
        >
          <form onSubmit={registerConfirmStep}>
            <div className="flex justify-between items-center mb-6">
              <div
                className="bg-white shadow-md p-2 rounded-full cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
                  alt="backward"
                  className="w-4 h-4"
                />
              </div>
              <h1 className="text-2xl font-semibold">ระบุข้อมูล</h1>
              <div></div>
            </div>

            <div className="space-y-6">
              <div>
                <label className="block text-gray-700">ID สมาชิก</label>
                <input
                  type="text"
                  placeholder="ระบุ ID สมาชิกที่ต้องการ"
                  onChange={inputValue("uname")}
                  value={uname}
                  className="w-full h-12 rounded-full px-4 py-2 border-2 border-gray-300 focus:border-blue-500 focus:outline-none"
                />
              </div>
              <div>
                <label className="block text-gray-700">รหัสผ่าน</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="กรุณาระบุรหัสผ่าน"
                    onChange={inputValue("password")}
                    value={password}
                    className="w-full h-12 rounded-full px-4 py-2 border-2 border-gray-300 focus:border-blue-500 focus:outline-none"
                  />
                  <img
                    src={showPassword ? HidePasswordIcon : ShowPasswordIcon}
                    alt="toggle-password-visibility"
                    className="absolute right-4 top-3 w-6 h-6 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                </div>
                {password.length > 0 && password.length < 8 && (
                  <p className="text-red-500 mt-2">รหัสผ่านขั้นต่ำ 8 ตัว</p>
                )}
                <p
                  className={`mt-2 ${
                    passwordStrength === "ไม่ปลอดภัย"
                      ? "text-red-500"
                      : passwordStrength === "ปานกลาง"
                      ? "text-yellow-800"
                      : "text-green-500"
                  }`}
                >
                  {passwordStrength && `ความปลอดภัยของรหัสผ่าน: ${passwordStrength}`}
                </p>
              </div>
              <div>
                <label className="block text-gray-700">ยืนยันรหัสผ่าน</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="กรุณายืนยันรหัสผ่าน"
                    onChange={inputValue("confirmPassword")}
                    value={confirmPassword}
                    className="w-full h-12 rounded-full px-4 py-2 border-2 border-gray-300 focus:border-blue-500 focus:outline-none"
                  />
                  <img
                    src={showPassword ? HidePasswordIcon : ShowPasswordIcon}
                    alt="toggle-password-visibility"
                    className="absolute right-4 top-3 w-6 h-6 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                </div>
                {upload.password !== upload.confirmPassword && (
                  <p className="text-red-500 mt-2">รหัสผ่านไม่เหมือนกัน</p>
                )}
              </div>
              <div>
                <label className="block text-gray-700">อีเมล</label>
                <input
                  type="text"
                  placeholder="กรุณาระบุอีเมล"
                  onChange={inputValue("email_address")}
                  value={email_address}
                  className="w-full h-12 rounded-full px-4 py-2 border-2 border-gray-300 focus:border-blue-500 focus:outline-none"
                />
              </div>
              <div>
                <label className="block text-gray-700">อายุ</label>
                <input
                  type="number"
                  placeholder="อายุ"
                  onChange={inputValue("age")}
                  value={age}
                  className="w-full h-12 rounded-full px-4 py-2 border-2 border-gray-300 focus:border-blue-500 focus:outline-none"
                />
              </div>
              <div>
                <label className="block text-gray-700">ID ผู้แนะนำ</label>
                <input
                  type="text"
                  placeholder={idFromURLRegister}
                  defaultValue={idFromURLRegister}
                  value={inviter_uname}
                  onChange={(e) => {
                    inputValue("inviter_uname")(e);
                    inviter_uname_search(e.target.value);
                  }}
                  className="w-full h-12 rounded-full px-4 py-2 border-2 border-gray-300 focus:border-blue-500 focus:outline-none"
                />
                {dataInviterUnameFromAPI.length > 0 && (
                  <div className="mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
                    {dataInviterUnameFromAPI.map((user, idx) => (
                      <div
                        key={idx}
                        className="p-2 cursor-pointer hover:bg-gray-100"
                        data-uname={user.uname}
                        onClick={handleSelectedInviter}
                      >
                        {user.uname}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex items-center mt-4">
                <input
                  type="checkbox"
                  checked={statusConfirmPolicy}
                  id="policy"
                  name="policy"
                  onChange={handlePolicyButton}
                  className="mr-2"
                />
                <label
                  htmlFor="policy"
                  className="text-gray-700 cursor-pointer underline"
                  onClick={handlePopupPolicy}
                >
                  ยอมรับ ข้อตกลงและเงื่อนไขการใช้เว็บไซต์
                </label>
              </div>
              <button
                type="submit"
                className={`${statusConfirmPolicy
                  ? "bg-blue-500 text-white w-full h-12 rounded-full mt-6 hover:bg-blue-600 transition-colors"
                  : "bg-gray-500 text-white w-full h-12 rounded-full mt-6"
                }`}
              >
                สมัครสมาชิกทันที
              </button>
            </div>
          </form>
        </motion.div>
      )}
      {popupPolicy && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-xl max-w-3xl w-full max-h-[80vh] overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-4">ข้อตกลงและเงื่อนไขการใช้เว็บไซต์</h2>
            <p className="text-gray-700 mb-6 whitespace-pre-wrap">{policyText}</p>
            <div className="flex justify-end">
              <button
                onClick={handleConfirmPolicy}
                className="bg-blue-500 text-white px-4 py-4 w-full rounded-full hover:bg-blue-600 transition-colors"
              >
                ยอมรับ
              </button>
            </div>
          </div>
        </div>
      )}
      {statusPage === 3 && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-gradient-to-r from-blue-50 to-blue-100 h-screen flex justify-center items-center"
        >
          <div className="bg-white shadow-lg rounded-xl p-8">
            <div className="text-black text-2xl text-center mb-6">
              <h1>ขั้นตอนสุดท้ายแล้ว..</h1>
            </div>
            <div className="flex justify-center items-center mb-8">
              <img
                src="https://cdn-icons-png.flaticon.com/512/16449/16449658.png"
                alt="final-step"
                style={{ width: "120px", height: "120px" }}
              />
            </div>

            <div className="otp-verify-container">
              <form onSubmit={otpConfirm}>
                <div className="mb-4">
                  <input
                    type="text"
                    name="phone"
                    placeholder="เบอร์โทรศัพท์"
                    required
                    value={check_phoneNumber}
                    className="w-full h-12 px-4 py-2 mb-3 border-2 border-gray-600 bg-black text-white placeholder-gray-400 focus:border-blue-500 focus:outline-none text-center"
                  />
                </div>

                <div className="mb-4 flex justify-center space-x-1">
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={otp[index] || ""}
                      onChange={(e) => handleOtpChange(e, index)}
                      className="w-10 h-10 text-center border-2 border-gray-300 focus:border-blue-500 focus:outline-none focus:shadow-outline-blue"
                    />
                  ))}
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white w-full h-12 rounded-full hover:bg-blue-600 transition-colors"
                  onClick={otpConfirm}
                >
                  ยืนยัน OTP
                </button>
              </form>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default RegisterNew;