import React from 'react';
import Modal from 'react-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { motion } from 'framer-motion';
import QRCode from 'qrcode.react'; // Import QRCode component

Modal.setAppElement('#root'); // Ensure this matches your app's root element

const ShareModal = ({ isOpen, onRequestClose, shareUrl }) => {
  const shareToLine = () => {
    const lineShareUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(shareUrl)}`;
    window.open(lineShareUrl, '_blank');
  };

  const shareToFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(facebookShareUrl, '_blank');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Share Asset"
      className="fixed inset-0 flex items-center justify-center z-50 p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-40"
      shouldCloseOnOverlayClick={true}
    >
      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative"
      >
        <button
          onClick={onRequestClose}
          className="absolute top-2 right-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
        >
          ปิด
        </button>
        <h2 className="text-2xl font-bold mb-4 text-center">ลิ้งค์แชร์อสังหา</h2>
        <input
          type="text"
          value={shareUrl}
          readOnly
          className="w-full p-3 border rounded mb-4 text-gray-700"
          onFocus={(e) => e.target.select()}
        />
        <CopyToClipboard text={shareUrl}>
          <button
            className="w-full p-3 bg-pink-400 text-white rounded hover:bg-blue-700 mb-4"
            onClick={() => alert('Copied to clipboard!')}
          >
            ก๊อปปี้ลงคลิปบอร์ด
          </button>
        </CopyToClipboard>
        <div className="flex justify-between space-x-4 mb-6">
          <button
            onClick={shareToLine}
            className="flex items-center p-2 bg-green-500 text-white rounded hover:bg-green-700 w-40 text-sm"
          >
            <img src="https://cdn-icons-png.flaticon.com/512/2504/2504922.png" alt="Line Icon" className="w-6 h-6 mr-2" />
            แชร์ไปยัง Line
          </button>
          <button
            onClick={shareToFacebook}
            className="flex items-center p-2 bg-blue-500 text-white rounded hover:bg-blue-900 w-40 text-sm"
          >
            <img src="https://cdn-icons-png.flaticon.com/512/5968/5968764.png" alt="Facebook Icon" className="w-6 h-6 mr-2" />
            แชร์ไปยัง Facebook
          </button>
        </div>
        {/* QR Code Section */}
        <div className="flex justify-center">
          <div className="text-center">
            <QRCode
              value={shareUrl}
              size={300} // Adjust size as needed
              level="H" // High error correction level
              includeMargin={true}
            />
            <p className="mt-2 text-sm text-gray-600">สามารถส่งให้เพื่อน Scan เพื่อดูข้อมูลอสังหาฯได้</p>
          </div>
        </div>
      </motion.div>
    </Modal>
  );
};

export default ShareModal;