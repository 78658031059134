import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import { CopyToClipboard } from 'react-copy-to-clipboard';

Modal.setAppElement('#root');

const ContactModal = ({ isOpen, onRequestClose, contactInfo }) => {

  useEffect(() => {
    console.log(contactInfo);
    // Uncomment the line below if you want to log OwnerContact as well
    // console.log(contactInfo?.OwnerContact);
  }, [contactInfo]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Contact Information"
      className="fixed inset-0 flex items-center justify-center z-50 p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      shouldCloseOnOverlayClick={true}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-lg shadow-md p-6 relative"
      >
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          onClick={onRequestClose}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 6L18 18" />
          </svg>
        </button>
        <h2 className="text-2xl font-medium mb-4 text-center">ข้อมูลติดต่อผู้ขาย</h2>
        {contactInfo?.OwnerContact && (
          <div className="border-2 border-gray-300 rounded-lg p-4 mb-4">
            <div className="flex items-center space-x-4">
              <img
                src={contactInfo.OwnerContact.profile_image || 'https://cdn-icons-png.flaticon.com/512/21/21294.png'}
                alt="Profile"
                className="w-16 h-16 rounded-full object-cover"
              />
              <div>
                <p className="text-xl font-semibold">
                  {contactInfo.OwnerContact.uname || 'Unknown User'}
                </p>

                <p className="text-xs text-red-500 mt-1">
                  {contactInfo?.OwnerContact?.lead_asset === true 
                    ? '*ผู้ Lead ไม่ใช่เจ้าของทรัพย์*' 
                    : contactInfo?.OwnerContact?.user_status === 'agent' &&  contactInfo?.OwnerContact?.lead_asset === false 
                      ? '*นายหน้าโพสต์*' 
                      : contactInfo?.OwnerContact?.user_status === 'owner' 
                        ? '*เจ้าของทรัพย์โพสต์*' 
                        : ''}
                </p>

              </div>
            </div>
          </div>
        )}


        <div className="space-y-4">
          {contactInfo && (
            <>
              <div className="flex flex-col items-center justify-between">
                <a href={`tel:${contactInfo.AssetContact.PhoneNumber}`} className="text-blue-500 underline flex items-center space-x-2">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/9073/9073336.png"
                    alt="Phone Icon"
                    className="w-8 h-8"
                  />
                  <span className="text-2xl text-center">{contactInfo.AssetContact.PhoneNumber}</span>
                </a>
                <button
                  onClick={() => window.location.href = `tel:${contactInfo.AssetContact.PhoneNumber}`}
                  className="w-full mt-2.5 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  กดโทรหาเบอร์ในป้าย
                </button>
              </div>

              {contactInfo.AssetContact?.Line && (
                <div className="flex items-center space-x-2">
                  <a
                    href={`https://line.me/R/ti/p/${contactInfo.AssetContact.Line}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2"
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/3536/3536785.png"
                      alt="LINE Icon"
                      className="w-12 h-8"
                    />
                  </a>
                  <input
                    type="text"
                    value={contactInfo.AssetContact.Line}
                    readOnly
                    className="w-full p-2 border rounded text-green-500 font-bold text-center text-xl"
                    onFocus={(e) => e.target.select()}
                  />
                  <CopyToClipboard text={contactInfo.AssetContact.Line}>
                    <button className="ml-2 p-1 bg-green-500 text-white rounded hover:bg-green-600">
                      Copy
                    </button>
                  </CopyToClipboard>
                </div>
              )}
            </>
          )}
        </div>
        <button
          onClick={onRequestClose}
          className="w-full mt-12 p-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          ปิดเมนูนี้
        </button>
      </motion.div>
    </Modal>
  );
};

export default ContactModal;
