import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import { motion } from 'framer-motion';
import { ArrowLeft, Users, Copy, ChevronRight } from 'lucide-react';

const MembersLayer = () => {
  const [uName, setUName] = useState("");
  const [MembersLayerData, setMemberLayerData] = useState(null);
  const memberRefID = `postperty.co/register?ID=${uName}`;
  const token = Cookies.get("jwt");

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => setUName(res.data.uname))
      .catch((error) => console.error(error));
  }, [token]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/mlm/invited`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => setMemberLayerData(res.data))
      .catch((error) => console.error(error));
  }, [token]);

  const copyLinkInvite = async () => {
    await navigator.clipboard.writeText(memberRefID);
    Swal.fire("คัดลอกสำเร็จ", "สามารถนำลิงค์นี้แชร์ให้เพื่อนของคุณได้", "success");
  };

  const MemberCard = ({ level, count }) => (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="w-full"
    >
      <Link 
        to={`/members/layer/desc?data=${level}`}
        className="bg-white/90 backdrop-blur-md rounded-xl p-4 shadow-lg flex items-center justify-between hover:bg-white/95 transition-all duration-300"
      >
        <div className="flex items-center gap-4">
          <div className="bg-gradient-to-r from-blue-600 to-blue-400 h-12 w-12 flex items-center justify-center rounded-lg text-white font-bold">
            {level}
          </div>
          <div className="flex flex-col">
            <span className="text-gray-600 text-sm">ชั้นที่ {level}</span>
            <span className={`font-medium ${count === 0 ? 'text-red-400' : 'text-blue-900'}`}>
              {count !== 0 ? `สมาชิก ${count} คน` : 'ไม่มีสมาชิกในชั้นนี้'}
            </span>
          </div>
        </div>
        <ChevronRight className="text-blue-600 w-5 h-5" />
      </Link>
    </motion.div>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      className="min-h-screen w-full bg-cover bg-center bg-fixed py-6 px-4"
      style={{ 
        backgroundImage: `url('https://images.unsplash.com/photo-1554050857-c84a8abdb5e2?q=80&w=2127&auto=format&fit=crop')`,
      }}
    >
    
      <div className="max-w-2xl mx-auto space-y-6">
     
        {/* Header */}
        <div className="bg-white/80 backdrop-blur-md rounded-2xl p-4 shadow-lg">
          <div className="flex items-center justify-between">
            <Link to="/profiles" className="flex items-center gap-2 text-blue-600 hover:text-blue-700">
              <ArrowLeft className="w-5 h-5" />
              <span className="font-medium">โปรไฟล์</span>
            </Link>
            <h1 className="text-blue-900 text-lg font-semibold">สมาชิกของฉัน</h1>
            <div className="w-20" />
          </div>
        </div>

        {/* Total Members Card */}
        <div className="bg-white/80 backdrop-blur-md rounded-2xl p-6 shadow-lg text-center">
          <h2 className="text-blue-900 font-medium mb-4">สมาชิกทั้งหมด</h2>
          <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl p-6 shadow-inner">
            <div className="flex items-center justify-center gap-3">
              <span className="text-5xl font-bold text-blue-600">
                {MembersLayerData ? MembersLayerData.Overall_Invited : '0'}
              </span>
              <Users className="w-8 h-8 text-blue-600" />
            </div>
          </div>
        </div>

            {/* Invite Button */}
            <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={copyLinkInvite}
          className="w-full bg-gradient-to-r from-blue-600 to-blue-400 text-white py-3 px-6 rounded-xl shadow-lg flex items-center justify-center gap-2 hover:from-blue-700 hover:to-blue-500 transition-all duration-300"
        >
          <Copy className="w-5 h-5" />
          <span>คัดลอกลิงค์เชิญ</span>
        </motion.button>

        {/* Member Layers */}
        <div className="space-y-3">
          {Array.from({ length: 10 }, (_, i) => (
            MembersLayerData && MembersLayerData.TotalLayer[`L${i + 1}`] !== undefined && (
              <MemberCard
                key={i + 1}
                level={i + 1}
                count={MembersLayerData.TotalLayer[`L${i + 1}`]}
              />
            )
          ))}
        </div>

        <div className='h-[130px]'></div>

     
      </div>
    </motion.div>
  );
};

export default MembersLayer;